
import targetsApi from "@/backend/api/targetsApi";
import TargetDto from "@/models/dtos/TargetDto";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "TargetsView",
})
export default class TargetsView extends Vue {
  targets: TargetDto[] = [];
  headers: any[] = [
    { text: "Name", value: "name" },
    { text: "Description", value: "description" },
    { text: "Password", value: "password" },
    { text: "Latitude", value: "latitude" },
    { text: "Longitude", value: "longitude" },
    { text: "Previous target", value: "previousTarget.name" },
    { text: "", value: "actions" },
  ];
  loading: boolean = false;

  mounted() {
    this.loadTargets();
  }

  async loadTargets() {
    var response = await targetsApi.getTargets();
    this.targets = response.data;
  }

  editTarget(id: number = 0) {
    this.$router.push(`/target/${id}`);
  }

  showTargetOnMap(item: TargetDto) {
    var coordinates = `${item.latitude}N,${item.longitude}E`;
    var url = `https://www.mapy.cz/?q=${coordinates}`;
    window.open(url, "_blank");
  }
}
