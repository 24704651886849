
import targetsApi from "@/backend/api/targetsApi";
import usersApi from "@/backend/api/usersApi";
import BaseResponse from "@/models/dtos/BaseResponse";
import TargetDto from "@/models/dtos/TargetDto";
import UserDto from "@/models/dtos/UserDto";
import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "UserDetail",
})
export default class UserDetail extends Vue {
	userId: number = parseInt(this.$attrs.id);
	loading: boolean = false;
	user: UserDto = new UserDto();
	targets: TargetDto[] = [];

	mounted() {
		this.loadData();
	}

	async loadData() {
		this.loading = true;

		if (this.userId > 0) {
			var response = await usersApi.getUser(this.userId);
			this.user = response.data;
		}

		var targetsResponse = await targetsApi.getTargets();
		this.targets = targetsResponse.data;

		this.loading = false;
	}

	async saveUser() {
		this.loading = true;

		if (this.userId == 0) {
			var createResponse = await usersApi.createUser(this.user);
			this.user = new UserDto();
		} else {
			var updateResponse = await usersApi.updateUser(this.user);
			this.user = updateResponse.data;
		}

		this.loading = false;
	}

	generateRandomPin() {
		var r = Math.floor(Math.random() * 10000);
		this.user.pin = String(r).padStart(4, "0");
	}
}
