
import { Component, Vue } from "vue-property-decorator";
import Coordinates from "@/models/Coordinates";

@Component({
	name: "GeolocationPanel",
})
export default class GeolocationPanel extends Vue {
	updateInterval: number = 0;

	geolocationId: number = 0;

	options: any = {
		enableHighAccuracy: true,
	};

	coordinates: Coordinates = new Coordinates(0, 0, 0);

	success = (position: any) => {
		let coords = position.coords;
		console.log(
			"🔵 -> file: RadarView.vue:56 -> RadarView -> coords:",
			coords
		);

		let lat = coords.latitude;
		let lng = coords.longitude;
		let accuracy = coords.accuracy;

		let coordinates = new Coordinates(lat, lng, accuracy);
		this.updateStatus(coordinates);
	};

	updateStatus(coordinates: Coordinates) {
		this.coordinates = coordinates;
    this.$emit('coordinatesUpdate', this.coordinates);
	}

	error = (err: any) => {
		console.warn(err);
	};

	mounted() {
		this.initTracking();
	}

	beforeDestroy() {
		this.stopTracking();
	}

	//  Geolocation
	initTracking() {
		if ("geolocation" in navigator) {
			this.geolocationId = navigator.geolocation.watchPosition(
				this.success,
				this.error,
				this.options
			);
		} else {
			console.error("NO GEOLOCATION");
		}
	}

	stopTracking() {
		console.log(
			"🔵 -> file: RadarView.vue:178 -> RadarView -> stopTracking -> stopTracking:",
			"GPS TRACKING STOPPED"
		);
		navigator.geolocation.clearWatch(this.geolocationId);
	}
}
