
import usersApi from "@/backend/api/usersApi";
import UserDto from "@/models/dtos/UserDto";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "UsersView",
})
export default class UsersView extends Vue {
  users: UserDto[] = [];
  headers: any[] = [
    { text: "Username", value: "username" },
    { text: "Pin", value: "pin" },
    { text: "Current target", value: "activeTarget.name" },
    { text: "", value: "actions" },
  ];
  loading: boolean = false;

  mounted() {
    this.loadUsers();
  }

  async loadUsers() {
    this.loading = true;
    var response = await usersApi.getUsers();
    this.users = response.data;
    this.loading = false;
  }

  editUser(id: number = 0) {
    this.$router.push(`/user/${id}`);
  }
}
