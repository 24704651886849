
import targetsApi from "@/backend/api/targetsApi";
import GeolocationPanel from "@/components/target/geolocation-panel.vue";
import Coordinates from "@/models/Coordinates";
import TargetDto from "@/models/dtos/TargetDto";
import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "TargetDetail",
  components: {
    GeolocationPanel
  }
})
export default class TargetDetail extends Vue {
	targetId: number = parseInt(this.$attrs.id);
	target: TargetDto = new TargetDto();
	targets: TargetDto[] = [];
	loading: boolean = false;

  coordinates: Coordinates = new Coordinates(0, 0, 0);

  onCoordinatesUpdate(coordinates: Coordinates){
    this.coordinates = coordinates;
  }

  setCurrentCoordinates(){
    this.target.latitude = this.coordinates.latitude.toString();
    this.target.longitude = this.coordinates.longitude.toString();
  }

	mounted() {
		this.loadTarget();
	}

	async loadTarget() {
		this.loading = true;

		if (this.targetId > 0) {
			var response = await targetsApi.getTarget(this.targetId);
			this.target = response.data;
		}

		var targetsResponse = await targetsApi.getTargets();
		this.targets = targetsResponse.data.filter((x) => x.id != this.targetId);

		this.loading = false;
	}

	async saveTarget() {
		this.loading = true;

		if (this.targetId == 0) {
			var createResponse = await targetsApi.createTarget(this.target);
			this.target = createResponse.data;
			this.$router.replace(`/target/${this.target.id}`);
			this.targetId = this.target.id;
		} else {
			var updateResponse = await targetsApi.updateTarget(this.target);
			this.target = updateResponse.data;
		}

		this.loading = false;
	}
}
