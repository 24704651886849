import api from "@/backend/api/api";
import BaseResponse from "@/models/dtos/BaseResponse";
import TargetDto from "@/models/dtos/TargetDto";

export default {
  url: "targets/",

  async getTargets() : Promise<BaseResponse<TargetDto[]>> {
    return api.get(this.url + "getTargets");
  },

  async getTarget(id: number):Promise<BaseResponse<TargetDto>> {
    return api.get(this.url + "getTarget/" + id);
  },

  async createTarget(target: TargetDto): Promise<BaseResponse<TargetDto>> {
    return api.post(this.url + "createTarget", target);
  },

  async updateTarget(target: TargetDto): Promise<BaseResponse<TargetDto>> {
    return api.post(this.url + "updateTarget", target);
  }
}