
import usersApi from "@/backend/api/usersApi";
import UserDto from "@/models/dtos/UserDto";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Map",
})
export default class Map extends Vue {
  users: UserDto[] = [];

  $refs!: {
    gMap: any;
  };

  updateInterval: number = 0;
  selectedUser: UserDto | null = null;

  headers: any[] = [
    { text: "Username", value: "username" },
    { text: "Last update", value: "timeStampLastUpdate" },
    { text: "Active target", value: "activeTarget.name" },
    { text: "Coordinates", value: "coordinates" },
    { text: "Center map", value: "centerMap" },
  ];

  loadingUsers: boolean = false;

  async loadUsers() {
    this.loadingUsers = true;
    var response = await usersApi.getUsers();
    this.users = response.data;
    this.loadingUsers = false;

    // if (this.users.length > 0) {
    //   this.$refs.gMap.panTo(this.getPosition(this.users[0]));
    // }
  }

  getPosition(user: UserDto) {
    var position = { lat: user.latitude, lng: user.longitude };
    return position;
  }

  centerOnUser(user: UserDto) {
    this.$refs.gMap.panTo(this.getPosition(user));
  }

  onMarkerClick(user: UserDto) {
    console.warn(user);
    this.selectedUser = user;
  }

  mounted() {
    this.loadUsers();
    this.updateInterval = setInterval(() => this.loadUsers(), 5000);
  }

  beforeDestroy() {
    clearInterval(this.updateInterval);
  }

  getDiffInSeconds(timestamp: string): number {
    var now = new Date().getTime();
    var lastUpdate = new Date(timestamp);
    var diff = (now - lastUpdate.getTime()) / 1000;

    return diff;
  }

  getTimeFromLastUpdate(timestamp: string) {
    var diffInSeconds = this.getDiffInSeconds(timestamp);
    var result = `${Math.floor(diffInSeconds / 60)}m ${Math.floor(
      diffInSeconds % 60
    )}s`;
    return result;
  }

  getTimeFromLastUpdateColor(timestamp: string) {
    var diffInSeconds = this.getDiffInSeconds(timestamp);
    if (diffInSeconds <= 60) {
      return "success";
    }
    if (diffInSeconds <= 180) {
      return "warning";
    }
    return "error";
  }

  get markers(): UserDto[] {
    return this.users.filter((x) => this.hasCoordinates(x));
  }

  hasCoordinates(user: UserDto) {
    return user.latitude && user.longitude;
  }

  getCoordinates(user: UserDto) {
    return `${user.latitude}N ${user.longitude}E`;
  }
}
