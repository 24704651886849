import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import HomeView from "@/views/HomeView.vue";
import UsersView from "@/views/UsersView.vue";
import UserDetail from "@/views/UserDetail.vue";
import TargetsView from "@/views/TargetsView.vue";
import TargetDetail from "@/views/TargetDetail.vue";
import MapView from "@/views/MapView.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/users",
    name: "users",
    component: UsersView
  },
  {
    path: "/user/:id",
    name: "userDetail",
    component: UserDetail,
    props: true,
  },
  {
    path: "/targets",
    name: "targets",
    component: TargetsView
  },
  {
    path: "/target/:id",
    name: "targetDetail",
    component: TargetDetail,
    props: true,
  },
  {
    path: "/map",
    name: "map",
    component: MapView,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
