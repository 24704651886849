import TargetDto from "./TargetDto";

export default class UserDto {
  id!: number;
  timestampCreated!: Date;
  TimeStampLastUpdate!: Date;
  TimeStampDeleted!: Date | null;
  username!: string;
  pin!: string;
  latitude!: number | null;
  longitude!: number | null;

  activeTargetId!: number | null;
  activeTarget!: TargetDto | null;
}