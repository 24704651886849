import BaseResponse from "@/models/dtos/BaseResponse";
import api from "./api";
import UserDto from "@/models/dtos/UserDto";

export default {
  url: "users/",

  async getUsers() :Promise<BaseResponse<UserDto[]>> {
    return api.get(this.url + "getUsers");
  },

  async getUser(id: number): Promise<BaseResponse<UserDto>> {
    return api.get(this.url + "getUser/" + id);
  },

  async createUser(model: UserDto): Promise<BaseResponse<UserDto>> {
    return api.post(this.url + "createUser", model);
  },

  async updateUser(model: UserDto): Promise<BaseResponse<UserDto>> {
    return api.post(this.url + "updateUser", model);
  }
}