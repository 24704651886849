const TOEKN_KEY = "token";

const store = {

  setToken(token: string) {
    localStorage.setItem(TOEKN_KEY, token);
  },

  getToken(): string | null {
    return localStorage.getItem(TOEKN_KEY);
  }

}

export default store;