export default class TargetDto {
  id!: number;
  timestampCreated!: Date;
  timeStampLastUpdate!: Date;
  TimeStampDeleted!: Date | null;

  name!: string;
  description!: string;
  latitude!: string;
  longitude!: string;
  password!: string;

  previousTargetId!: number | null;
}