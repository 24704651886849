
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "HomeView",
})
export default class HomeView extends Vue {
  links: any[] = [
    { text: "Users", to: "/users", icon: "mdi-account-group" },
    { text: "Targets", to: "/targets", icon: "mdi-target" },
    { text: "Map", to: "/map", icon: "mdi-map" },
  ];
}
